import PropTypes from 'prop-types';
import { Stack, Box } from '@mui/material';
import { Title, Switch, Loader } from 'modules/Core/Common';

export function ItemToggle({
  title,
  loading,
  active,
  disabled = false,
  onChange,
  sx,
}) {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: 185,
        maxWidth: {
          xs: '100%',
          sm: 200,
        },
        ...(sx || {}),
      }}
    >
      <Title as="span" size="mico" color="gray" fontWeight="normal">
        {title}
      </Title>
      {loading ? (
        <Box width={52}>
          <Loader width="24px" />
        </Box>
      ) : (
        <Switch active={active} disabled={disabled} onChange={onChange} />
      )}
    </Stack>
  );
}

ItemToggle.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

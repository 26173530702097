import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

export const TableActions = ({ children }) => (
  <Stack
    sx={{
      flexDirection: 'row',
    }}
  >
    {children}
  </Stack>
);

TableActions.propTypes = {
  children: PropTypes.node,
};

import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Title } from 'modules/Core/Common';

export function ItemLabel({ flexDirection = 'column', title, sx, children }) {
  return (
    <Stack
      sx={{
        flexDirection,
        gap: 1,
        ...(sx || {}),
      }}
    >
      <Title size="mico" color="gray" as="p" fontWeight="normal">
        {title}
      </Title>
      {typeof children === 'string' ? (
        <Title size="default" as="p" style={{ lineHeight: '1.6rem' }}>
          {children}
        </Title>
      ) : (
        children
      )}
    </Stack>
  );
}

ItemLabel.propTypes = {
  flexDirection: PropTypes.oneOf(['row', 'column']),
  title: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

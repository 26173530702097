import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const Placeholder = styled.div`
  padding: 3rem;

  @media ${device.tablet} {
    width: 100%;
    border-right: 0;
    border-bottom: 0.1rem solid ${(props) => props.theme.config.colors.gray_200};
  }
`;

export const PlaceholderItem = styled.div`
  width: 10rem;
  margin-bottom: 0.8rem;

  span {
    height: 2.4rem;
    border-radius: 0.6rem;
  }
`;

export const FlexList = styled.div`
  display: flex;

  svg {
    margin: 0 0.4rem;
    width: 0.8rem;

    path {
      fill: ${(props) => props.theme.config.colors.gray_300};
    }
  }
`;

export const Span = styled.div`
  font-size: 1.2rem;
  color: ${(props) => props.theme.config.colors.text_base};
  display: inline;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

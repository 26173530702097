import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import i18n from 'i18next';

import { Modal, Icon, Button } from 'modules/Core/Common';
import * as S from './ModalExportSuccess.styles';

const ModalExportSuccess = ({
  isOpen,
  exportToEmail,
  onExportAgain,
  onDone,
}) => {
  const theme = useTheme();

  return (
    <Modal
      width="500"
      maxHeight="569"
      background={theme.config.colors.light}
      isOpen={isOpen}
      setIsOpen={onDone}
      offCloseOnOverlayClick
    >
      <S.Container>
        <S.ModalHeader>
          <S.IconBox>
            <Icon
              name={!exportToEmail ? 'check' : 'time'}
              color={theme.config.colors.green}
              size={32}
            />
          </S.IconBox>
          <S.Title>
            {!exportToEmail
              ? i18n.t('commons.modal-export.success.all-done')
              : i18n.t('commons.modal-export.success.email-sent')}
          </S.Title>
          <S.Description>
            {!exportToEmail ? (
              <>
                {i18n.t('commons.modal-export.success.download-successfully')}
                <br />
                {i18n.t('commons.modal-export.success.click-export-again')}
              </>
            ) : (
              <>
                {i18n.t('commons.modal-export.success.being-prepared')}
                <br />
                {i18n.t('commons.modal-export.success.you-receive-email')}
              </>
            )}
          </S.Description>
        </S.ModalHeader>

        <S.ModalFooter>
          {!exportToEmail && (
            <Button transparent medium onClick={onExportAgain}>
              {i18n.t('commons.modal-export.success.export-again')}
            </Button>
          )}
          <Button medium onClick={onDone}>
            {i18n.t('commons.modal-export.success.done')}
          </Button>
        </S.ModalFooter>
      </S.Container>
    </Modal>
  );
};

ModalExportSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onExportAgain: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  exportToEmail: PropTypes.bool,
};

export default ModalExportSuccess;

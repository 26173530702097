import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Stack } from '@mui/material';
import { UserAvatar, TableEmptyData, Tooltip } from 'modules/Core/Common';
import { formatNumberWithPlusPrefix } from 'modules/Utils';

import * as S from './UsersAvatarsCount.styles';

export const UsersAvatarsCount = ({
  students,
  studentsCount = 0,
  tooltip,
  onClick,
}) => {
  if (!students?.length) {
    return <TableEmptyData />;
  }

  return (
    <Tooltip
      placement="bottom"
      overlay={
        tooltip || i18n.t('modules.users.users-avatars-count.default-tooltip')
      }
      disabled={!studentsCount}
    >
      <Stack
        onClick={() => !!studentsCount && onClick()}
        sx={{
          maxWidth: '100px',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: studentsCount ? 'pointer' : 'default',
          gap: 1,
          '> div': {
            '&:not(:first-of-type)': {
              ml: studentsCount ? '-20px' : '',
            },
          },
        }}
      >
        {students?.map((student) => (
          <UserAvatar
            key={student.id}
            user={student}
            showCard={!studentsCount}
          />
        ))}
        {!!studentsCount && (
          <S.UsersCount>
            {formatNumberWithPlusPrefix(studentsCount)}
          </S.UsersCount>
        )}
      </Stack>
    </Tooltip>
  );
};

UsersAvatarsCount.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      avatar: PropTypes.object,
    })
  ),
  studentsCount: PropTypes.number,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
};

import { toUpper, capitalize } from 'lodash';

export const CATEGORY_OVERALL = 'overall';
export const CATEGORY_READING = 'reading';
export const CATEGORY_LISTENING = 'listening';
export const CATEGORY_SPEAKING = 'speaking';
export const CATEGORY_WRITING = 'writing';
export const CATEGORY_READING_WRITING = 'reading_writing';
export const CATEGORY_MATH = 'math';

const RAW_CATEGORIES = [
  CATEGORY_OVERALL,
  CATEGORY_READING,
  CATEGORY_LISTENING,
  CATEGORY_SPEAKING,
  CATEGORY_WRITING,
  CATEGORY_READING_WRITING,
  CATEGORY_MATH,
];

export const allCategories = RAW_CATEGORIES.map((category) => ({
  name: capitalize(category),
  tag: toUpper(category),
}));

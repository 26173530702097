import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Stack } from '@mui/material';
import { Title } from 'modules/Core/Common';
import { useHeaderInfo } from '../../hooks';
import { I18N_PREFIX } from '../../HeaderInfo.utils';
import * as styles from './DeviceInfo.styles';

export function DeviceInfo({ sx }) {
  const { deviceInfo } = useHeaderInfo();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        ...styles.containerSx,
        backgroundColor: theme.config.colors.gray_50,
        border: `1px solid ${theme.config.colors.gray_200}`,
        ...(sx || {}),
      }}
    >
      <Title as="h4" size="default" color="gray">
        {i18n.t(`${I18N_PREFIX}.device-info.title`)}
      </Title>
      <Stack
        sx={{
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            sm: 'center',
          },
          gap: 3,
          '> div:not(:last-of-type)': {
            borderRight: {
              sm: '1px solid',
            },
            borderBottom: {
              xs: '1px solid',
              sm: 0,
            },
            borderColor: {
              xs: theme.config.colors.gray_300,
              sm: theme.config.colors.gray_300,
            },
            pr: {
              sm: 3,
            },
            pb: {
              xs: 3,
              sm: 0,
            },
          },
        }}
      >
        <Stack sx={styles.itemSx}>
          <Title as="h5" size="mico" fontWeight="normal">
            {i18n.t(`${I18N_PREFIX}.device-info.type`)}
          </Title>
          <Title as="span" size="default">
            {deviceInfo.type}
          </Title>
        </Stack>
        <Stack sx={styles.itemSx}>
          <Title as="h5" size="mico" fontWeight="normal">
            {i18n.t(`${I18N_PREFIX}.device-info.model`)}
          </Title>
          <Title as="span" size="default">
            {deviceInfo.model}
          </Title>
        </Stack>
        <Stack sx={styles.itemSx}>
          <Title as="h5" size="mico" fontWeight="normal">
            {i18n.t(`${I18N_PREFIX}.device-info.os`)}
          </Title>
          <Title as="span" size="default">
            {deviceInfo.os}
          </Title>
        </Stack>
        <Stack sx={styles.itemSx}>
          <Title as="h5" size="mico" fontWeight="normal">
            {i18n.t(`${I18N_PREFIX}.device-info.browser`)}
          </Title>
          <Title as="span" size="default">
            {deviceInfo.browser}
          </Title>
        </Stack>
      </Stack>
    </Stack>
  );
}

DeviceInfo.propTypes = {
  sx: PropTypes.object,
};

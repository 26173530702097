import { lazy } from 'react';
import { menuBuilder } from 'services/Routes.service';
import { ContentIcon } from '@edusynch/edusynch-svg-icons';

const ContentTypesContainer = lazy(() =>
  import('modules/ContentTypes/ContentTypesList/ContentTypesList.container')
);

const ContentTypesMenu = menuBuilder([
  {
    Icon: ContentIcon,
    name: 'Content Types',
    component: ContentTypesContainer,
    ability: 'content_types',
  },
]).addSubmenu();

export default ContentTypesMenu;

const FilterProgressCheck = (tests, hideProgressCheck = false) => {
  if (!tests.length) return [];

  const VALID_TESTS = hideProgressCheck
    ? tests.filter((test) => test.tag !== 'PROGRESS_CHECK')
    : tests;

  return VALID_TESTS;
};

export { FilterProgressCheck };

import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Title, UserAvatar } from 'modules/Core/Common';
import { I18N_PREFIX } from '../../HeaderInfo.utils';

export function Evaluators({ evaluators, sx }) {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: 185,
        ...(sx || {}),
      }}
    >
      <Title as="span" size="mico" fontWeight="normal">
        {i18n.t(`${I18N_PREFIX}.evaluator`)}
      </Title>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {evaluators?.map((evaluator) => (
          <UserAvatar key={evaluator.id} user={evaluator} showCard />
        ))}
      </Stack>
    </Stack>
  );
}

Evaluators.propTypes = {
  evaluators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      avatar: PropTypes.object,
    })
  ),
  sx: PropTypes.object,
};

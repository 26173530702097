import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Stack } from '@mui/material';

export function ItemsColumn({ sx, children }) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        gap: 2,
        '> div:not(:last-of-type)': {
          borderBottom: `1px solid ${theme.config.colors.gray_200}`,
          pb: 2,
        },
        ...(sx || {}),
      }}
    >
      {children}
    </Stack>
  );
}

ItemsColumn.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

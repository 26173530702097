import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import { PLANS_SUBSCRIPTION_URL } from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';

let cancelToken;

const formatData = (data) => {
  const planItems = data.plan_items?.length
    ? data.plan_items.map((planItem) => ({
        ...planItem,
        item_type: planItem.item_type ? 'checked' : 'unchecked',
      }))
    : [];

  const subscriptionData = formDataFromObj({
    ...data,
    plan_items: planItems,
  });

  return subscriptionData;
};

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    testId: null,
    whitelabelId: null,
    period: null,
    status: null,
    search: '',
    startDate: null,
    endDate: null,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestSubscription: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestCreateSubscription: (state) => {
      state.loading = true;
      state.error = null;
    },
    changeSubscriptionPage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    receiveSubscriptionList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        subscription: action.payload.plans,
        total: action.payload.total_items,
      };
    },
    clearSubscriptionList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        testId: null,
        whitelabelId: null,
        startDate: null,
        endDate: null,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    receiveSubscriptionError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
  },
});

const Actions = subscriptionSlice.actions;

const Selectors = {
  fetchListData: (state) => state.subscription,
  subscriptionLoading: ({ subscription: { loading } }) => ({ loading }),
};

const Async = {
  fetchSubscriptionList: () => async (dispatch, getState) => {
    const {
      subscription: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestSubscription());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: `${PLANS_SUBSCRIPTION_URL}/list`,
        params: {
          page,
          paginates_per: perPage,
          search,
          plan_type: 'SUBSCRIPTION',
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveSubscriptionList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveSubscriptionError(e.message);
      }
    }

    action && dispatch(action);
  },

  createSubscription:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestCreateSubscription());

      try {
        const response = await request({
          method: 'POST',
          url: `${PLANS_SUBSCRIPTION_URL}/create`,
          data: formatData(data),
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveSubscriptionError());
        onError(e);
      }
    },

  getSubscriptionById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSubscription());

      try {
        const response = await request({
          method: 'GET',
          url: `${PLANS_SUBSCRIPTION_URL}/get_plan_by_id?id=${id}`,
        });

        action = Actions.receiveRequestSuccess();
        onSuccess(response);
      } catch (e) {
        action = Actions.receiveSubscriptionError();
        onError(e);
      }

      dispatch(action);
    },

  updateSubscription:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestSubscription());
      try {
        const response = await request({
          method: 'PUT',
          url: `${PLANS_SUBSCRIPTION_URL}/update`,
          data: formatData(data),
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveSubscriptionError());
        onError(e);
      }
    },

  deleteSubscription:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestSubscription());
      try {
        const response = await request({
          method: 'DELETE',
          url: `${PLANS_SUBSCRIPTION_URL}/delete?id=${id}`,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveSubscriptionError());
        onError(e);
      }
    },
};

const { reducer } = subscriptionSlice;

export { reducer, Actions, Async, Selectors };

import { useTheme } from 'styled-components';
import { Grid, Stack, Card, Skeleton } from '@mui/material';

export function HeaderInfoPlaceholder() {
  const theme = useTheme();

  return (
    <Card elevation={0}>
      <Grid
        container
        sx={{
          height: 'inherit',
          p: {
            xs: 2,
            sm: 4,
          },
          backgroundColor: theme.config.colors.gray_50,
          borderBottom: `1px solid ${theme.config.colors.gray_200}`,
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            placeSelf: 'center',
            pr: {
              xs: 0,
              sm: 2,
              md: 5,
            },
          }}
        >
          <Stack
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              alignItems: 'center',
              position: 'relative',
              gap: 2,
            }}
          >
            <Skeleton
              variant="circular"
              sx={{ width: 40, height: 40, flexShrink: 0, transform: 'unset' }}
            />
            <Stack
              sx={{
                flexDirection: 'column',
                gap: 1,
                width: '100%',
              }}
            >
              <Skeleton sx={{ height: '2.4rem', transform: 'unset' }} />
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 0.5,
                }}
              >
                <Skeleton
                  sx={{ width: '60%', height: '1.6rem', transform: 'unset' }}
                />
                <Skeleton
                  sx={{ width: '40%', height: '1.6rem', transform: 'unset' }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          sx={{
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${theme.config.colors.gray_200}`,
              sm: 'none',
            },
            mt: {
              xs: 3,
              sm: 0,
            },
            pt: {
              xs: 3,
              sm: 0,
            },
            pl: {
              xs: 0,
              sm: 2,
              md: 5,
            },
            borderLeft: {
              xs: 'none',
              sm: `1px solid ${theme.config.colors.gray_200}`,
            },
          }}
        >
          <Stack
            sx={{
              width: '100%',
              flexDirection: {
                xs: 'column',
              },
              gap: {
                xs: 2,
                sm: 4,
              },
            }}
          >
            <Skeleton
              sx={{
                width: { xs: '100%', sm: '25rem' },
                height: '1.4rem',
                transform: 'unset',
              }}
            />
            <Stack
              sx={{
                flexDirection: 'row',
                gap: 3,
              }}
            >
              <Stack
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                  flexDirection: 'row',
                  alignItems: {
                    md: 'center',
                  },
                  gap: 0.5,
                }}
              >
                <Skeleton
                  variant="circular"
                  sx={{ width: 14, height: 14, transform: 'unset' }}
                />
                <Skeleton
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '8rem',
                    },
                    height: '1.4rem',
                    transform: 'unset',
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                  flexDirection: 'row',
                  alignItems: {
                    md: 'center',
                  },
                  gap: 0.5,
                }}
              >
                <Skeleton
                  variant="circular"
                  sx={{ width: 14, height: 14, transform: 'unset' }}
                />
                <Skeleton
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '8rem',
                    },
                    height: '1.4rem',
                    transform: 'unset',
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        sx={{
          p: {
            xs: 2,
            sm: 4,
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          gap: {
            xs: '3rem',
            sm: '5.6rem',
          },
        }}
      >
        <Stack sx={{ width: 200, gap: 1 }}>
          <Skeleton sx={{ width: '30%', transform: 'unset' }} />
          <Skeleton sx={{ width: '100%', transform: 'unset' }} />
        </Stack>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            width: 200,
            gap: 1,
          }}
        >
          <Skeleton sx={{ width: '100%', height: 16, transform: 'unset' }} />
          <Skeleton
            variant="circular"
            sx={{ width: 24, height: 24, flexShrink: 0, transform: 'unset' }}
          />
        </Stack>
      </Stack>
    </Card>
  );
}

import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { ArrowRightIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './UserInstitutionsInfo.styles';

export const UserInstitutionsInfo = ({ student }) => {
  if (!student?.group) return null;

  return (
    <S.FlexList>
      {student?.group?.name && (
        <S.ArrowContainer>
          <S.Span>{capitalize(student?.group?.name)}</S.Span>
        </S.ArrowContainer>
      )}
      {student?.unit?.name && (
        <S.ArrowContainer>
          <ArrowRightIcon />
          <S.Span>{capitalize(student?.unit?.name)}</S.Span>
        </S.ArrowContainer>
      )}
      {student?.school?.name && (
        <S.ArrowContainer>
          <ArrowRightIcon />
          <S.Span>{capitalize(student?.school?.name)}</S.Span>
        </S.ArrowContainer>
      )}
    </S.FlexList>
  );
};

UserInstitutionsInfo.propTypes = {
  student: PropTypes.shape({
    group: PropTypes.object,
    unit: PropTypes.object,
    school: PropTypes.object,
  }),
};

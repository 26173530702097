import styled from 'styled-components';

export const StyledLink = styled.a`
  font-size: 1.2rem;
  line-height: 1.4rem;
  border-bottom: 1px solid transparent;
  transition: color 0.2s, border-color 0.2s;
  color: ${(props) => props.theme.config.colors.gray};

  &:hover {
    color: ${(props) => props.theme.config.colors.primary};
    border-color: ${(props) => props.theme.config.colors.primary};
  }
`;

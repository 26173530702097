import { useMemo, useCallback } from 'react';
import { CEFR_NAME, MCER_NAME, PLACEMENT_NAME } from 'constants';

const useTestType = ({ testType }) => {
  const upperTestType = useMemo(
    () => (testType || '').toUpperCase(),
    [testType]
  );

  const { isCefr, isMcer, isPlacement } = useMemo(
    () => ({
      isCefr: upperTestType.includes(CEFR_NAME),
      isMcer: upperTestType.includes(MCER_NAME),
      isPlacement: upperTestType.includes(PLACEMENT_NAME),
    }),
    [upperTestType]
  );

  const getScoreByTestType = useCallback(
    (score) => {
      if (isCefr || isPlacement) return score.score_cefr;
      if (isMcer) return score.score_mcer;
      return score.score;
    },
    [isCefr, isMcer, isPlacement]
  );

  const isPending = useCallback(
    (score) => {
      if (isCefr || isPlacement) return !score.score_cefr;
      if (isMcer) return !score.score_mcer;
      return !score.score;
    },
    [isCefr, isMcer, isPlacement]
  );

  return {
    isCefr,
    isMcer,
    isPlacement,
    getScoreByTestType,
    isPending,
  };
};

export { useTestType };

import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Stack } from '@mui/material';
import { Title, Icon, LabelWithBackground } from 'modules/Core/Common';
import { useHeaderInfo } from '../../hooks';
import { I18N_PREFIX } from '../../HeaderInfo.utils';
import { allCategories } from 'modules/Submissions/Utils';
import * as styles from './ScoreInfo.styles';
import { useTestType } from 'modules/Core/Hooks';

export function ScoreInfo({ isCustomTest, sx }) {
  const { scoreInfo, testType } = useHeaderInfo();
  const theme = useTheme();
  const { getScoreByTestType, isPending } = useTestType({ testType });

  const renderScore = (score) => {
    if (isPending(score)) {
      return (
        <Icon name="pending" color={theme.config.colors.primary} size="12px" />
      );
    }

    const scoreLabel = getScoreByTestType(score);

    return (
      <LabelWithBackground
        small
        secondary
        label={scoreLabel}
        style={{
          width: 'auto',
          minHeight: '24px',
          padding: '0.4rem 1.2rem',
          fontWeight: 'bold',
          lineHeight: '1.6rem',
        }}
      />
    );
  };

  return (
    <Stack
      sx={{
        ...styles.containerSx,
        borderTop: `1px solid ${theme.config.colors.gray_200}`,
        ...(sx || {}),
      }}
    >
      <Title as="h4" size="default" color="gray">
        {i18n.t(`${I18N_PREFIX}.score-info.title`)}
      </Title>
      <Stack
        sx={{
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            sm: 'center',
          },
          gap: 3,
          '> div:not(:last-of-type)': {
            borderRight: {
              sm: '1px solid',
            },
            borderBottom: {
              xs: '1px solid',
              sm: 0,
            },
            borderColor: {
              xs: theme.config.colors.gray_300,
              sm: theme.config.colors.gray_300,
            },
            pr: {
              sm: 3,
            },
            pb: {
              xs: 3,
              sm: 0,
            },
          },
        }}
      >
        {allCategories?.map((category) => {
          const score = scoreInfo?.find((score) => score.tag === category.tag);
          if (!score) return null;
          return (
            <Stack key={score.tag} sx={styles.itemSx}>
              <Title as="h5" size="mico" fontWeight="normal">
                {isCustomTest
                  ? score?.name
                  : i18n.t(
                      `${I18N_PREFIX}.score-info.${score?.tag?.toLowerCase()}`
                    )}
              </Title>
              {renderScore(score)}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}

ScoreInfo.propTypes = {
  isCustomTest: PropTypes.bool,
  sx: PropTypes.object,
};

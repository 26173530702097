export const STATUS_IN_PROGRESS = 'in_progress';
export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';

export const defaultDomain = {
  domain: '',
  url: '',
  error: null,
  lock: false,
};

export const validateDomain = (domain) => {
  const regex = /^(https:\/\/\S+)$/;
  return regex.test(domain);
};

export const lockDomainsFromApi = (domains) => {
  const formattedDomains = domains?.map((item) => ({
    ...item,
    lock: validateDomain(item?.domain),
  }));
  return formattedDomains || [defaultDomain];
};

export const formatDomainsToApi = (domains) => {
  const formattedDomains = domains
    ?.filter((item) => validateDomain(item?.domain))
    ?.map((item) => ({
      id: item?.id,
      ...(!item?._destroy && { url: item?.domain }),
      _destroy: item?._destroy,
    }));
  return JSON.stringify(formattedDomains);
};

export const createIntegrationModel = (integration = {}) => {
  return {
    id: integration.id || null,
    name: integration.name || '',
    externalSoftwareId: integration.external_software?.id || '',
    versionId: integration.version?.id || integration.version_id || '',
    groupId: integration.group?.id || '',
    key: integration.key || '',
    domains: lockDomainsFromApi(integration?.external_application_domains),
  };
};

export const createIntegrationRequestModel = (integration = {}) => {
  return {
    id: integration.id,
    name: integration.name,
    external_software_id:
      integration.externalSoftwareId || integration.external_software_id,
    version_id: integration.versionId || integration.version_id,
    group_id: integration.groupId || integration.group_id,
    key: integration.key,
    domains: formatDomainsToApi(integration?.domains),
  };
};

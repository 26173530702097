import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Stack, Card } from '@mui/material';
import { HeaderInfoPlaceholder } from './HeaderInfo.placeholder';
import { HeaderInfoContext } from './context';
import { UserInfo } from './components/UserInfo';
import { DeviceInfo } from './components/DeviceInfo';
import { ScoreInfo } from './components/ScoreInfo';

export function HeaderInfo({
  testType,
  student,
  id,
  date,
  dateLabel,
  data,
  deviceInfo,
  scoreInfo,
  categories,
  selectedCategory,
  onChangeCategory,
  isLoading,
  isCustomTest,
  sx,
  children,
}) {
  const currentCategory = useMemo(() => {
    return categories?.find((category) => category.id === selectedCategory);
  }, [categories, selectedCategory]);

  if (isLoading) {
    return <HeaderInfoPlaceholder />;
  }

  return (
    <HeaderInfoContext.Provider
      value={{
        testType,
        student,
        data,
        deviceInfo,
        scoreInfo,
        categories,
        selectedCategory,
        currentCategory,
        onChangeCategory,
      }}
    >
      <Card elevation={0} sx={{ ...(sx || {}) }}>
        <UserInfo student={student} id={id} date={date} dateLabel={dateLabel} />
        <Stack
          sx={{
            p: {
              xs: 2,
              sm: 4,
            },
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            gap: {
              xs: '3rem',
              sm: '5.6rem',
            },
          }}
        >
          {children}
        </Stack>
        {!!deviceInfo && <DeviceInfo />}
        {!!scoreInfo && <ScoreInfo isCustomTest={isCustomTest} />}
      </Card>
    </HeaderInfoContext.Provider>
  );
}

HeaderInfo.propTypes = {
  testType: PropTypes.string,
  student: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.object,
  }),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  dateLabel: PropTypes.string,
  data: PropTypes.object,
  categories: PropTypes.array,
  selectedCategory: PropTypes.any,
  onChangeCategory: PropTypes.func,
  deviceInfo: PropTypes.object,
  scoreInfo: PropTypes.array,
  isLoading: PropTypes.bool,
  isCustomTest: PropTypes.bool,
  sx: PropTypes.object,
  children: PropTypes.node,
};

import styled from 'styled-components';

export const ButtonDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 113px;
  height: 32px;
  border-radius: 4px;
  outline: none;
  background: ${(props) => props.theme.config.colors.primary_200};
  border: none;
  color: ${(props) => props.theme.config.colors.primary};
  cursor: default;
`;

export const TooltipDetails = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};

  p::selection {
    background: ${(props) => props.theme.config.colors.gray_400};
  }
`;

export const UserButtonContainerStyled = styled.div`
  max-width: 113px;
  display: flex;
  justify-content: center;
`;

import styled, { css } from 'styled-components';

const Label = styled.label`
  ${(props) => props.theme.config.typograph.xsmall};
  padding: 0 0 0.8rem;
  color: ${(props) => props.theme.config.colors.text_base};
  display: inline-block;

  ${({ required }) =>
    required &&
    css`
      display: flex;
      justify-content: space-between;
      span + span {
        text-transform: uppercase;
        font-size: 1.1rem;
      }
    `}
`;

export { Label };

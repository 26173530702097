import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Label.styles';
import i18n from 'i18next';

import { Tooltip } from 'modules/Core/Common';

const Label = ({
  children,
  htmlFor,
  showTooltip,
  tooltipPlacement,
  required,
  ...props
}) => {
  if (showTooltip) {
    return (
      <Tooltip
        placement={tooltipPlacement}
        overlay={children}
        overlayInnerStyle={{
          backgroundColor: '#0075EA',
          borderRadius: '4px',
          padding: '16px',
        }}
      >
        <S.Label htmlFor={htmlFor} {...props}>
          {children}
        </S.Label>
      </Tooltip>
    );
  }
  if (required) {
    return (
      <S.Label htmlFor={htmlFor} required={required}>
        <span>{children}</span>
        <span>{i18n.t('common-words.required')}</span>
      </S.Label>
    );
  }
  return <S.Label htmlFor={htmlFor}>{children}</S.Label>;
};

Label.defaultProps = {
  showTooltip: false,
  tooltipPlacement: 'bottom',
  required: false,
};

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  htmlFor: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  required: PropTypes.bool,
};

export default Label;

export const getEnabledTestFieldNameByTestTag = (
  testTag,
  isCamelCase = true
) => {
  switch (testTag) {
    case 'A2_FLYERS':
      return isCamelCase ? 'a2FlyersEnabled' : 'a2_flyers_enabled';
    case 'CEFR_LEVEL_TEST':
      return isCamelCase ? 'cefrEnabled' : 'cefr_enabled';
    case 'CEFR_RL':
      return isCamelCase ? 'cefrRlEnabled' : 'cefr_rl_enabled';
    case 'IELTS_ACADEMIC':
      return isCamelCase ? 'ieltsAcademicEnabled' : 'ielts_academic_enabled';
    case 'TOEFL':
      return isCamelCase ? 'toeflEnabled' : 'toefl_enabled';
    case 'SAT':
      return isCamelCase ? 'satEnabled' : 'sat_enabled';
    case 'TOEIC_LR':
      return isCamelCase ? 'toeicLrEnabled' : 'toeic_lr_enabled';
    case 'PLACEMENT_TEST':
      return isCamelCase ? 'placementTestEnabled' : 'placement_test_enabled';
    case 'MCER':
      return isCamelCase ? 'mcerEnabled' : 'mcer_enabled';
    case 'LINGUASKILL_BUSINESS':
      return isCamelCase
        ? 'linguaskillBusinessEnabled'
        : 'linguaskill_business_enabled';
    case 'LINGUASKILL_GENERAL':
      return isCamelCase
        ? 'linguaskillGeneralEnabled'
        : 'linguaskill_general_enabled';
    default:
      break;
  }
};

export const getHideTestFieldNameByTestTag = (testTag, isCamelCase = true) => {
  switch (testTag) {
    case 'A2_FLYERS':
    case 'CAMBRIDGE':
      return isCamelCase ? 'a2FlyersHide' : 'a2_flyers_hide';
    case 'CEFR_LEVEL_TEST':
      return isCamelCase ? 'cefrHide' : 'cefr_hide';
    case 'CEFR_RL':
      return isCamelCase ? 'cefrRlHide' : 'cefr_rl_hide';
    case 'MCER':
      return isCamelCase ? 'mcerHide' : 'mcer_hide';
    case 'IELTS_ACADEMIC':
      return isCamelCase ? 'ieltsAcademicHide' : 'ielts_academic_hide';
    case 'TOEFL':
      return isCamelCase ? 'toeflHide' : 'toefl_hide';
    case 'SAT':
      return isCamelCase ? 'satHide' : 'sat_hide';
    case 'PLACEMENT_TEST':
      return isCamelCase ? 'placementTestHide' : 'placement_test_hide';
    case 'TOEIC_LR':
      return isCamelCase ? 'toeicLrHide' : 'toeic_lr_hide';
    case 'LINGUASKILL_BUSINESS':
      return isCamelCase
        ? 'linguaskillBusinessHide'
        : 'linguaskill_business_hide';
    case 'LINGUASKILL_GENERAL':
      return isCamelCase
        ? 'linguaskillGeneralHide'
        : 'linguaskill_general_hide';
    default:
      break;
  }
};

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { FormGroup, Label, Dropzone, Icon } from 'modules/Core/Common';

import imgPlaceholder from 'assets/avatar/placeholder.png';

import * as S from './CertificateImage.styles';

const I18N_PREFIX = 'commons';

export const CertificateImage = ({
  fieldName,
  values,
  errors,
  size,
  handleChange,
  handleChangeInfo,
  required = false,
}) => {
  const isDeleted = useMemo(() => {
    return values[fieldName] && values[fieldName] === 'delete';
  }, [values[fieldName]]);

  const emptyImage = !values[fieldName] || isDeleted;

  const hasImage = useMemo(() => {
    return values[fieldName] && !isDeleted;
  }, [values[fieldName], isDeleted]);

  const imageUrl = useMemo(() => {
    if (isDeleted || !values[fieldName]) return imgPlaceholder;
    if (typeof values[fieldName] === 'string') {
      return values[fieldName];
    }
    return URL.createObjectURL(values[fieldName]);
  }, [values[fieldName], isDeleted]);

  return (
    <FormGroup>
      <Label htmlFor={fieldName} required={required}>
        {i18n.t(`${I18N_PREFIX}.certificate.${fieldName}`)}
      </Label>
      <Dropzone
        isFilesSelected={hasImage}
        accept=".jpeg, .jpg, .png, .svg, image/png, image/jpeg"
        onDropAccepted={(files) =>
          handleChangeInfo(fieldName, files?.[0], handleChange)
        }
      >
        {emptyImage ? (
          <S.DropContainer error={errors[fieldName]}>
            <img src={imageUrl} alt="Placeholder" />
            <div>
              <strong>{i18n.t('commons.image-upload.drag-image')}</strong>
              <span>
                {size || '250x250'}{' '}
                {i18n.t('commons.image-upload.drag-image-size')}
              </span>
            </div>
          </S.DropContainer>
        ) : (
          <S.DropImageContainer>
            <img src={imageUrl} alt="Uploaded" />
          </S.DropImageContainer>
        )}
      </Dropzone>

      {hasImage ? (
        <S.RemoveImage
          onClick={() => handleChangeInfo(fieldName, 'delete', handleChange)}
        >
          <Icon name="close" />
        </S.RemoveImage>
      ) : null}
    </FormGroup>
  );
};

CertificateImage.propTypes = {
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  size: PropTypes.string,
  handleChange: PropTypes.func,
  handleChangeInfo: PropTypes.func,
};

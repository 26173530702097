import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Tooltip } from 'modules/Core/Common';
import {
  ButtonDetails,
  TooltipDetails,
  UserButtonContainerStyled,
} from './styles';
import { TableEmptyData } from 'modules/Core/Common';

const overlayInnerStyle = {
  backgroundColor: '#0075EA',
  borderRadius: '4px',
  padding: '16px',
};

const UserDetailsSingle = ({ associationNames }) => {
  const [prefix, ...rest] = associationNames.split(': ');

  const TooltipContent = () => (
    <TooltipDetails>
      <p>{prefix}</p>
    </TooltipDetails>
  );
  return (
    <Tooltip
      placement="bottom"
      overlay={TooltipContent}
      overlayInnerStyle={overlayInnerStyle}
    >
      <UserButtonContainerStyled>{rest}</UserButtonContainerStyled>
    </Tooltip>
  );
};

UserDetailsSingle.propTypes = {
  associationNames: PropTypes.string.isRequired,
};

const UserButtonDetails = ({ data }) => {
  const associationNames = data.association_names || [];

  switch (associationNames.length) {
    case 0:
      return (
        <UserButtonContainerStyled>
          <TableEmptyData />
        </UserButtonContainerStyled>
      );
    case 1:
      return <UserDetailsSingle associationNames={associationNames[0]} />;
    default:
      return (
        <Tooltip
          placement="bottom"
          overlay={
            <TooltipDetails>
              {associationNames.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </TooltipDetails>
          }
          overlayInnerStyle={overlayInnerStyle}
        >
          <ButtonDetails>
            {i18n.t('commons.buttons.show-details-button')}
          </ButtonDetails>
        </Tooltip>
      );
  }
};

UserButtonDetails.propTypes = {
  data: PropTypes.object,
};

export default UserButtonDetails;

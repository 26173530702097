export const containerSx = {
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
  alignItems: {
    sm: 'center',
  },
  gap: 4,
  py: 2,
  px: 4,
};

export const itemSx = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
};

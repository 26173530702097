import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Stack, ButtonBase } from '@mui/material';
import { Icon } from 'modules/Core/Common';

import * as S from './ItemLink.styles';

const baseLinkSx = {
  fontSize: '1.2rem',
  lineHeight: '1.4rem',
  borderBottom: '1px solid transparent',
  transition: 'color 0.2s, border-color 0.2s',
};

export function ItemLink({ icon, title = '', href, sx, onClick }) {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        ...(sx || {}),
      }}
    >
      <Icon name={icon} size="12px" color={theme.config.colors.primary} />
      {href ? (
        <S.StyledLink href={href} target="_blank" rel="noopener noreferrer">
          {title}
        </S.StyledLink>
      ) : (
        <ButtonBase
          type="button"
          onClick={onClick}
          sx={{
            ...baseLinkSx,
            color: theme.config.colors.gray,
            '&:hover': {
              color: theme.config.colors.primary,
              borderColor: theme.config.colors.primary,
            },
          }}
        >
          {title}
        </ButtonBase>
      )}
    </Stack>
  );
}

ItemLink.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  sx: PropTypes.object,
  onClick: PropTypes.func,
};

import styled, { css } from 'styled-components';

const getSize = (props) => {
  if (props.large || props.size === 'large') {
    return '4.8rem';
  }

  if (props.xlarge || props.size === 'xlarge') {
    return '8rem';
  }

  return '3.2rem';
};

const getTypograph = (props) => {
  if (props.large) {
    return props.theme.config.typograph.xsmall;
  }

  if (props.xlarge) {
    return props.theme.config.typograph.secondary;
  }

  return props.theme.config.typograph.mico;
};

const Img = styled.img`
  border-radius: 100%;
  overflow: hidden;
  height: ${(props) => getSize(props)};
  width: ${(props) => getSize(props)};
  max-width: 100%;
  min-width: ${(props) => props.theme.config.spacing.layout_3};
  flex-shrink: 0;

  ${({ showCursorPointer }) =>
    showCursorPointer &&
    css`
      cursor: pointer;
    `}

  ${({ isSquare }) =>
    isSquare &&
    css`
      border-radius: 0.4rem;
    `}
`;

const UnloadedImage = styled.div`
  ${(props) => getTypograph(props)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 2px solid ${(props) => props.theme.config.colors.primary};
  background: ${(props) => props.theme.config.colors.light};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '50%'};
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.config.colors.secondary};
  user-select: none;
  height: ${(props) => getSize(props)};
  width: ${(props) => getSize(props)};

  ${({ showCursorPointer }) =>
    showCursorPointer &&
    css`
      cursor: pointer;
    `}
`;

export { Img, UnloadedImage };

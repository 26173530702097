import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  BASE_TESTS_URL,
  CUSTOM_TESTS_URL,
  LICENSES_URL,
  LICENSES_STUDENT_URL,
  LICENSE_BY_ID_URL,
  LICENSES_MOUNT_FIELDS_URL,
  LICENSES_CREATE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import {
  createLicenseRequestModel,
  createLicenseProctoringRequestModel,
  createLicenseCustomTestRequestModel,
} from 'modules/Licenses/LicenseUtils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';

const currentStartDate = getUTCDefaultStartDate();
const currentEndDate = getUTCDefaultEndDate();

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    testId: null,
    groupId: null,
    customTestId: null,
    testTag: null,
    type: null,
    startDate: currentStartDate,
    endDate: currentEndDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  tests: {
    loading: false,
    data: [],
  },
  customTests: {
    loading: false,
    data: [],
  },
  licenseStudents: {
    licenseId: '',
    name: '',
    students: [],
    total: 0,
    page: 1,
    perPage: 8,
    search: '',
    showInfo: false,
    align: 'center',
    loading: false,
  },
  isDeleting: false,
};

const licensesSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    setTestsLoading: (state, action) => {
      state.tests.loading = action.payload;
    },
    setCustomTestsLoading: (state, action) => {
      state.customTests.loading = action.payload;
    },
    requestLicense: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success license list response
     */
    receiveTestsList: (state, action) => {
      state.tests.loading = false;
      state.tests = {
        ...state.tests,
        data: action.payload.tests,
      };
    },
    receiveCustomTestsList: (state, action) => {
      state.customTests.loading = false;
      state.customTests = {
        ...state.customTests,
        data: action.payload.custom_tests,
      };
    },
    receiveLicenseList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        licenses: action.payload.licenses,
        total: action.payload.total_items,
      };
    },
    clearLicensesList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
      state.licenseStudents = {
        licenseId: '',
        name: '',
        students: [],
        total: 0,
        page: 1,
        perPage: 8,
        search: '',
        showInfo: false,
        align: 'center',
        loading: false,
      };
    },
    clearLicenseStudents: (state) => {
      state.licenseStudents = {
        licenseId: '',
        name: '',
        students: [],
        total: 0,
        page: 1,
        perPage: 8,
        search: '',
        showInfo: false,
        align: 'center',
        loading: false,
      };
    },
    /**
     * receive an error response
     */
    receiveLicenseError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeLicensesPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeTestId: (state, action) => {
      state.data.testId = action.payload;
    },
    changeCustomTestId: (state, action) => {
      state.data.customTestId = action.payload;
    },
    changeStartDate: (state, action) => {
      state.data.startDate = action.payload;
    },
    changeEndDate: (state, action) => {
      state.data.endDate = action.payload;
    },
    changeLicensesSearch: (state, action) => {
      state.data.search = action.payload;
    },
    updateLicenseOnList: (state, action) => {
      const updLicense = action.payload;
      const index = state.data.licenses.findIndex(
        (license) => license.id === updLicense.id
      );
      if (index !== -1) state.data.licenses.splice(index, 1, updLicense);
    },
    changeLicensesFilters: (state, action) => {
      state.data.search = action.payload.search || '';
      state.data.testId = action.payload.test_id || null;
      state.data.customTestId = action.payload.custom_test_id || null;
      state.data.testTag =
        action.payload.test_tag || action.payload.testTag || '';
      state.data.type = action.payload.type || null;
      state.data.startDate = action.payload.start_date || currentStartDate;
      state.data.endDate = action.payload.end_date || currentEndDate;
      state.data.groupId =
        action.payload.group_id || action.payload.groupId || null;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
    },
    changeLicenseStudentsSearch: (state, action) => {
      state.licenseStudents.search = action.payload.search;
      state.licenseStudents.page = 1;
    },
    changeLicenseStudentsPage: (state, action) => {
      state.licenseStudents.page = action.payload;
    },
    requestStudents: (state) => {
      state.licenseStudents.loading = true;
      state.licenseStudents.error = null;
    },
    receiveStudentsList: (state, action) => {
      state.licenseStudents = {
        ...state.licenseStudents,
        licenseId: action.payload.id || state.licenseStudents.licenseId,
        name: action.payload.name || state.licenseStudents.name,
        students: action.payload.student_licenses,
        total: action.payload.total_items,
        loading: false,
      };
    },
    receiveStudentsError: (state, action) => {
      state.licenseStudents.loading = false;
      state.licenseStudents.error = action.payload;
    },
    clearStudents: (state) => {
      state.licenseStudents.search = '';
      state.licenseStudents.page = 1;
    },
    clearLicensesFilters: (state) => {
      state.data.search = '';
      state.data.testId = null;
      state.data.groupId = null;
      state.data.customTestId = null;
      state.data.testTag = null;
      state.data.type = null;
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.startDate = currentStartDate;
      state.data.endDate = currentEndDate;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    clearTestFilters: (state) => {
      state.data.testId = null;
      state.data.groupId = null;
      state.data.customTestId = null;
      state.data.testTag = null;
      state.data.type = null;
      state.data.page = 1;
    },
  },
});

const Actions = licensesSlice.actions;

const Selectors = {
  fetchListData: (state) => state.license,
  licenseLoading: ({ license: { loading } }) => ({ loading }),
};

const Async = {
  fetchTests: () => async (dispatch) => {
    let action;

    dispatch(Actions.setTestsLoading(true));

    try {
      const response = await request({
        method: 'GET',
        url: BASE_TESTS_URL,
        params: {
          page: 1,
          paginates_per: 10,
          sort: 'ASC',
          sort_by: 'name',
        },
      });

      action = Actions.receiveTestsList(response.data.content);
    } catch (e) {
      dispatch(Actions.setTestsLoading(false));
    }

    dispatch(action);
  },

  fetchCustomTests:
    ({ group_id, unit_id, school_id } = {}) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.setCustomTestsLoading(true));

      try {
        const response = await request({
          method: 'GET',
          url: CUSTOM_TESTS_URL,
          params: {
            page: 1,
            paginates_per: 10,
            group_id,
            unit_id,
            school_id,
            sort: 'ASC',
            sort_by: 'name',
          },
        });

        action = Actions.receiveCustomTestsList(response.data.content);
      } catch (e) {
        dispatch(Actions.setCustomTestsLoading(false));
      }

      dispatch(action);
    },

  fetchLicensesList: () => async (dispatch, getState) => {
    const {
      license: {
        data: {
          page,
          perPage,
          search,
          testId,
          groupId,
          customTestId,
          testTag,
          type,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestLicense());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: LICENSES_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          test_id: testId,
          group_id: groupId,
          custom_test_id: customTestId,
          test_tag: testTag,
          type,
          start_date: startDate,
          end_date: endDate,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveLicenseList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveLicenseError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchLicenseStudents:
    ({ id, name } = {}) =>
    async (dispatch, getState) => {
      const {
        license: {
          licenseStudents: {
            page,
            perPage,
            search,
            licenseId,
            name: licenseName,
          },
        },
      } = getState();

      if (!id && !licenseId) return;

      let action;

      dispatch(Actions.requestStudents());

      try {
        const response = await request({
          method: 'GET',
          url: LICENSES_STUDENT_URL,
          params: {
            license_id: id || licenseId,
            page,
            paginates_per: perPage,
            search,
          },
        });

        const data = {
          ...response.data.content,
          id: id || licenseId,
          name: name || licenseName,
        };

        action = Actions.receiveStudentsList(data);
      } catch (e) {
        if (e?.message?.includes('not found')) {
          action = Actions.receiveStudentsList({
            licenseId: licenseId,
            name: licenseName,
            students: [],
            total: 0,
            page: 1,
            perPage: 8,
            search: '',
            showInfo: false,
            align: 'center',
            loading: false,
          });
        } else {
          action = Actions.receiveStudentsError(e.message);
        }
      }

      dispatch(action);
    },

  getLicenseById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${LICENSE_BY_ID_URL}/${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  getLicenseTestAmount:
    ({
      id = null,
      studentId = null,
      testId,
      typeTest,
      selectedGroup,
      onSuccess,
      onError,
    }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: LICENSES_MOUNT_FIELDS_URL,
          params: {
            student_id: studentId,
            license_id: id,
            test_id: testId,
            type_test: typeTest,
            selected_group: selectedGroup,
          },
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  createLicense:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLicense());
      try {
        const isNormalLicense = !!data.testId;
        const isProctoringLicense = data?.eproctoring_license_type;

        const parsedLicense = isNormalLicense
          ? createLicenseRequestModel(data)
          : isProctoringLicense
          ? createLicenseProctoringRequestModel(data)
          : createLicenseCustomTestRequestModel(data);

        delete parsedLicense.id;

        const licenseData = formDataFromObj(parsedLicense);

        const response = await request({
          method: 'POST',
          url: LICENSES_CREATE_URL,
          data: licenseData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveLicenseError());
        onError(e);
      }
    },

  updateLicense:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLicense());
      try {
        const isNormalLicense = !!data.testId;
        const isProctoringLicense = data?.eproctoring_license_type;

        const parsedLicense = isNormalLicense
          ? createLicenseRequestModel(data)
          : isProctoringLicense
          ? createLicenseProctoringRequestModel(data)
          : createLicenseCustomTestRequestModel(data);

        const licenseData = formDataFromObj(parsedLicense);

        const response = await request({
          method: 'PUT',
          url: `${LICENSE_BY_ID_URL}/${data.id}`,
          data: licenseData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveLicenseError());
        onError(e);
      }
    },

  deleteLicense:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${LICENSE_BY_ID_URL}/${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  createLicenseProctoring:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLicense());
      try {
        const licenseData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: LICENSES_CREATE_URL,
          data: licenseData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveLicenseError());
        onError(e);
      }
    },

  updateLicenseProctoring:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestLicense());
      try {
        const licenseData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: `${LICENSE_BY_ID_URL}/${data.id}`,
          data: licenseData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveLicenseError());
        onError(e);
      }
    },
};

const { reducer } = licensesSlice;

export { reducer, Actions, Async, Selectors };

const createUnitModel = (unit = {}) => {
  return {
    id: unit.id || null,
    name: unit.name || '',
    groupId: unit.group?.id || '',
    slug: unit.slug || '',
    hideAnalysis: unit.hide_analysis || false,
    hideCustomTests: unit.hide_custom_tests || false,
    hideStudentCertReport: unit.hide_student_cert_report || false,
    checkSelectUrlPayment: unit.enabled_payment_url_custom || false,
    showOnSignup: unit.show_on_signup || false,
    signUpEmailSaml: unit.sign_up_email_saml || false,
    showOnSlug: unit.show_on_slug || false,
    hasSimulations: unit.has_simulations || false,
    hidePractice: unit.hide_practice || false,
    contentType: unit.content_type?.id || '',
  };
};

const createUnitRequestModel = (unit = {}) => {
  return {
    id: unit.id,
    name: unit.name,
    group_id: unit.groupId,
    slug: unit.slug,
    hide_analysis: unit.hideAnalysis,
    hide_custom_tests: unit.hideCustomTests,
    hide_student_cert_report: unit.hideStudentCertReport,
    enabled_payment_url_custom: unit.checkSelectUrlPayment,
    show_on_signup: unit.showOnSignup,
    sign_up_email_saml: unit.signUpEmailSaml,
    show_on_slug: unit.showOnSlug,
    has_simulations: unit.hasSimulations,
    hide_practice: unit.hidePractice,
    content_type_id: unit.contentType,
  };
};

export { createUnitModel, createUnitRequestModel };

import i18n from 'i18next';
import * as SUBMISSION_STATUS from '../Common/StatusLabel/StatusLabel.utils';
export const exerciseStatus = ['Completed', 'Pending'];
export const exerciseStatusCustom = [
  'All Status',
  'Completed',
  'Pending',
  'Skipped',
  'Scheduled',
  'Missed',
  'Canceled',
  'Done',
];

export const getSubmissionStatusLabel = (status) => {
  switch (status) {
    case SUBMISSION_STATUS.PENDING:
      return i18n.t('modules.submissions.status.pending');
    case SUBMISSION_STATUS.COMPLETE:
    case SUBMISSION_STATUS.DONE:
      return i18n.t('modules.submissions.status.completed');
    case SUBMISSION_STATUS.SCHEDULED:
      return i18n.t('modules.submissions.status.scheduled');
    case SUBMISSION_STATUS.CANCELED:
      return i18n.t('modules.submissions.status.canceled');
    case SUBMISSION_STATUS.MISSED:
      return i18n.t('modules.submissions.status.missed');
    case SUBMISSION_STATUS.SKIPPED:
      return i18n.t('modules.submissions.status.skipped');
    case SUBMISSION_STATUS.PARTIAL:
      return i18n.t('modules.submissions.status.partial');
    default:
      return 'N/A';
  }
};

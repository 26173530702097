export const containerSx = {
  flexDirection: {
    xs: 'column',
    sm: 'row',
  },
  flexWrap: 'wrap',
  alignItems: {
    sm: 'center',
  },
  gap: 4,
  borderRadius: '6px',
  p: 2,
  m: {
    xs: 2,
    sm: 4,
  },
  '&&': {
    mt: 0,
  },
};

export const itemSx = {
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 1,
};

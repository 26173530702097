import { mergeMenuArrays } from 'services/Routes.service';

import {
  UsersMenu,
  ContentMenu,
  OverallMenu,
  AntifraudMenu,
  LicensesMenu,
  PricingsMenu,
  SettingsMenu,
  DashboardMenu,
  WhitelabelMenu,
  SubmissionsMenu,
  SimulationsMenu,
  RoutesShortcuts,
  InstitutionsMenu,
  ResultsMenu,
  TestsMenu,
  CountryPermissionsMenu,
  ProctoringSettingsMenu,
  PlansMenu,
  PurchasesMenu,
  SoftwaresMenu,
  EdusynchLiveMenu,
  ContentTypesMenu,
} from './RoutesMenu';

const PrivateRoutes = mergeMenuArrays([
  DashboardMenu,
  ResultsMenu,
  ContentMenu,
  UsersMenu,
  InstitutionsMenu,
  PricingsMenu,
  LicensesMenu,
  OverallMenu,
  SubmissionsMenu,
  SimulationsMenu,
  WhitelabelMenu,
  AntifraudMenu,
  SettingsMenu,
  TestsMenu,
  CountryPermissionsMenu,
  ProctoringSettingsMenu,
  PlansMenu,
  PurchasesMenu,
  SoftwaresMenu,
  EdusynchLiveMenu,
  ContentTypesMenu,
]);

export { PrivateRoutes, RoutesShortcuts };

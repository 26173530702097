import { useTheme } from 'styled-components';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Stack, Autocomplete, TextField } from '@mui/material';
import { Title } from 'modules/Core/Common';
import { useHeaderInfo } from '../../hooks';
import { I18N_PREFIX } from '../../HeaderInfo.utils';

export function CategorySelect({ sx }) {
  const { categories, currentCategory, onChangeCategory } = useHeaderInfo();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        gap: 1,
        '.MuiSvgIcon-root': {
          fontSize: '1.6rem',
        },
        '.MuiAutocomplete-endAdornment': {
          top: 'unset',
        },
        '.MuiInputBase-input': {
          fontSize: '1.6rem',
          color: theme.config.colors.gray,
        },
        '.MuiAutocomplete-popper > *': {
          fontSize: '1.6rem',
          color: theme.config.colors.text_base,
        },
        ...(sx || {}),
      }}
    >
      <Title as="p" size="mico" color="gray" fontWeight="normal">
        {i18n.t(`${I18N_PREFIX}.category`)}
      </Title>
      <Autocomplete
        disablePortal
        options={categories}
        sx={{
          width: 240,
          '.MuiInputBase-root': { height: '4.8rem' },
        }}
        size="small"
        disableClearable
        value={currentCategory}
        getOptionLabel={(option) => option.name}
        noOptionsText={i18n.t(`${I18N_PREFIX}.no-options`)}
        onChange={(_, item) => onChangeCategory(item?.id)}
        renderInput={(params) => <TextField {...params} />}
      />
    </Stack>
  );
}

CategorySelect.propTypes = {
  sx: PropTypes.object,
};

import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import i18n from 'i18next';
import { Stack, Grid } from '@mui/material';
import { Avatar, Title, Icon } from 'modules/Core/Common';
import { format } from 'modules/Utils';
import { useAuth } from 'modules/Auth/Hooks';
import { UserInstitutionsInfo } from '../UserInstitutionsInfo';
import { I18N_PREFIX } from '../../HeaderInfo.utils';

export function UserInfo({ student, id, date, dateLabel }) {
  const theme = useTheme();
  const { userDateFormat } = useAuth();

  return (
    <Grid
      container
      sx={{
        height: 'inherit',
        p: {
          xs: 2,
          sm: 4,
        },
        backgroundColor: theme.config.colors.gray_50,
        borderBottom: `1px solid ${theme.config.colors.gray_200}`,
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{
          placeSelf: 'center',
          pr: {
            xs: 0,
            sm: 2,
            md: 5,
          },
        }}
      >
        <Stack
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            alignItems: 'center',
            position: 'relative',
            gap: 2,
          }}
        >
          <Avatar
            size="large"
            name={student?.name}
            src={student?.avatar?.thumb?.url}
            style={{ width: 40, height: 40, fontSize: '1.6rem' }}
          />
          <Stack
            sx={{
              gap: 0.5,
            }}
          >
            <Title
              color="primary"
              as="p"
              size="default"
              style={{ fontSize: '1.6rem' }}
            >
              {student?.name}
            </Title>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 0.5,
              }}
            >
              <Icon name="email" size="16px" />
              <Title as="p" size="mico" fontWeight="normal">
                {student?.email}
              </Title>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={8}
        sx={{
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${theme.config.colors.gray_200}`,
            sm: 'none',
          },
          mt: {
            xs: 3,
            sm: 0,
          },
          pt: {
            xs: 3,
            sm: 0,
          },
          pl: {
            xs: 0,
            sm: 2,
            md: 5,
          },
          borderLeft: {
            xs: 'none',
            sm: `1px solid ${theme.config.colors.gray_200}`,
          },
        }}
      >
        <Stack
          sx={{
            width: '100%',
            flexDirection: {
              xs: 'column',
            },
            gap: {
              xs: 2,
            },
          }}
        >
          <UserInstitutionsInfo student={student} />
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 3,
            }}
          >
            <Stack
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                alignItems: {
                  md: 'center',
                },
                gap: 0.5,
              }}
            >
              <Title size="mico" as="p" fontWeight="normal">
                {i18n.t(`${I18N_PREFIX}.id`)}
              </Title>
              <Title size="default" as="span">
                {id}
              </Title>
            </Stack>
            <Stack
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
                alignItems: {
                  md: 'center',
                },
                gap: 0.5,
              }}
            >
              <Title size="mico" as="p" fontWeight="normal">
                {dateLabel || i18n.t(`${I18N_PREFIX}.date`)}
              </Title>
              <Title size="default" as="span">
                {format(date, userDateFormat, 'parse')}
              </Title>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

UserInfo.propTypes = {
  student: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.object,
  }),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  dateLabel: PropTypes.string,
};

import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  USER_GROUP_ADMINS_URL,
  SETTINGS_REMOVE_AVATAR_URL,
  USER_GROUP_ADMIN_BY_ID_URL,
  USER_GROUP_ADMINS_CREATE_URL,
  USER_GROUP_ADMINS_UPDATE_URL,
  USER_GROUP_ADMINS_DELETE_URL,
  GROUPS_URL,
  LANGUAGES_URL,
  TIMEZONES_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultRequest } from 'modules/Utils/DefaultRequest';

export const INITIAL_GROUP_ADMIN_FILTERS_STATE = {
  groupId: null,
};

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    groupId: null,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  languages: {
    data: [],
    loading: false,
  },
  timezones: {
    data: [],
    loading: false,
  },
  groups: {
    data: [],
    loading: false,
  },
  isDeleting: false,
};

const groupAdminSlice = createSlice({
  name: 'groupAdmin',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestGroups: (state) => {
      state.groups = {
        ...state.groups,
        loading: true,
      };
    },
    receiveGroupsError: (state, action) => {
      state.groups = {
        ...state.groups,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveGroupsList: (state, action) => {
      state.groups = {
        ...state.groups,
        loading: false,
        data: action.payload.content.groups,
      };
    },
    requestGroupAdmin: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    requestLanguages: (state) => {
      state.languages = {
        ...state.languages,
        loading: true,
      };
    },
    requestTimezones: (state) => {
      state.timezones = {
        ...state.timezones,
        loading: true,
      };
    },
    receiveTimezonesList: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
        data: action.payload.content,
      };
    },
    receiveTimezonesError: (state, action) => {
      state.timezones = {
        ...state.timezones,
        loading: false,
      };
      state.error = action.payload;
    },
    receiveLanguagesError: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
      };
      state.error = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.lading = false;
    },
    /**
     * receive a success groupAdmin list response
     */
    receiveGroupAdminList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        groupAdmin: action.payload.users,
        total: action.payload.total_items,
      };
    },
    clearGroupAdminList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        groupId: null,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    /**
     * receive a success language list response
     */
    receiveLanguagesList: (state, action) => {
      state.languages = {
        ...state.languages,
        loading: false,
        data: action.payload.content,
      };
    },
    /**
     * receive an error response
     */
    receiveGroupAdminError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeGroupAdminPage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeGroupAdminSearch: (state, action) => {
      state.data.groupId = action.payload.group_id;
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
    changeGroupAdminFilters: (state, action) => {
      state.data.groupId = action.payload?.group_id || '';
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    clearGroupAdminsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
      state.data.groupId = null;
    },
  },
});

const Actions = groupAdminSlice.actions;

const Selectors = {
  fetchListData: (state) => state.groupAdmin,
  groupAdminLoading: ({ groupAdmin: { loading } }) => ({ loading }),
};

const Async = {
  fetchGroupAdminList: () => async (dispatch, getState) => {
    const {
      groupAdmin: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
          groupId,
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestGroupAdmin());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: USER_GROUP_ADMINS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          group_id: groupId,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveGroupAdminList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveGroupAdminError(e.message);
      }
    }

    action && dispatch(action);
  },

  createGroupAdmin:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestGroupAdmin());
      try {
        if (!data.avatar) delete data.avatar;
        const groupAdminData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: USER_GROUP_ADMINS_CREATE_URL,
          data: groupAdminData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveGroupAdminError());
        onError(e);
      }
    },

  removeAvatar:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: SETTINGS_REMOVE_AVATAR_URL,
          data,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  getGroupAdminById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${USER_GROUP_ADMIN_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  deleteGroupAdmin:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${USER_GROUP_ADMINS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  updateGroupAdmin:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestGroupAdmin());
      try {
        if (!(data.avatar instanceof File)) delete data.avatar;
        const groupAdminData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: USER_GROUP_ADMINS_UPDATE_URL,
          data: groupAdminData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveGroupAdminError());
        onError(e);
      }
    },

  fetchGroupsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      `${GROUPS_URL}?page=1&paginates_per=200`,
      'GET',
      Actions.requestGroups,
      Actions.receiveGroupsList,
      Actions.receiveGroupsError
    ),

  fetchLanguagesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      LANGUAGES_URL,
      'GET',
      Actions.requestLanguages,
      Actions.receiveLanguagesList,
      Actions.receiveLanguagesError
    ),

  fetchTimezonesList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      TIMEZONES_URL,
      'GET',
      Actions.requestTimezones,
      Actions.receiveTimezonesList,
      Actions.receiveTimezonesError
    ),
};

const { reducer } = groupAdminSlice;

export { reducer, Actions, Async, Selectors };

import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  CONTENT_TYPES_LIST_URL,
  CONTENT_TYPES_GET_BY_ID_URL,
  CONTENT_TYPES_CREATE_URL,
  CONTENT_TYPES_UPDATE_URL,
  CONTENT_TYPES_DELETE_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { defaultErrorToast } from 'modules/Utils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  contentTypeEdit: {
    loading: false,
    data: null,
  },
};

const contentTypesSlice = createSlice({
  name: 'contentTypes',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changePage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeContentTypesFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.search = action?.payload.search || '';
    },
    clearContentTypesFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
    clearContentTypeEdit: (state) => {
      state.contentTypeEdit.loading = false;
      state.contentTypeEdit.data = null;
    },
    requestContentTypes: (state) => {
      state.loading = true;
      state.error = null;
    },
    receiveContentTypesList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        contentTypes: action.payload.content_types,
        total: action.payload.total_items,
      };
    },
    receiveContentTypesError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    requestContentTypeEdit: (state) => {
      state.contentTypeEdit.loading = true;
    },
    receiveContentTypeEditSuccess: (state, action) => {
      state.contentTypeEdit.loading = false;
      state.contentTypeEdit.data = action.payload;
    },
    receiveContentTypeEditError: (state) => {
      state.contentTypeEdit.loading = false;
    },
    clearContentTypesList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
  },
});

const Actions = contentTypesSlice.actions;

const Selectors = {
  fetchListData: (state) => state.contentTypes,
  contentTypesLoading: ({ contentTypes: { loading } }) => ({ loading }),
};

const Async = {
  fetchContentTypesList: () => async (dispatch, getState) => {
    const {
      contentTypes: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestContentTypes());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: CONTENT_TYPES_LIST_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveContentTypesList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveContentTypesError(e.message);
      }
    }

    action && dispatch(action);
  },

  getContentTypeById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestContentTypeEdit());

      try {
        const response = await request({
          method: 'GET',
          url: `${CONTENT_TYPES_GET_BY_ID_URL}?id=${id}`,
        });

        action = Actions.receiveContentTypeEditSuccess(response.data.content);
        onSuccess?.(response.data.content);
      } catch (e) {
        action = Actions.receiveContentTypeEditError();
        defaultErrorToast(
          i18n.t('errors.error-sorry-an-error-occurred-during.get-content-type')
        )(e);
        onError?.(e);
      }

      dispatch(action);
    },

  createContentType:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestContentTypes());

      try {
        const formData = formDataFromObj(data);

        const response = await request({
          method: 'POST',
          url: CONTENT_TYPES_CREATE_URL,
          data: formData,
        });

        dispatch(Actions.setLoading(false));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveContentTypesError());
        onError(e);
      }
    },

  updateContentType:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestContentTypes());

      try {
        const formData = formDataFromObj(data);

        const response = await request({
          method: 'PUT',
          url: CONTENT_TYPES_UPDATE_URL,
          data: formData,
        });

        dispatch(Actions.setLoading(false));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveContentTypesError());
        onError(e);
      }
    },

  deleteContentType:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestContentTypes());

      try {
        const response = await request({
          method: 'DELETE',
          url: `${CONTENT_TYPES_DELETE_URL}?id=${id}`,
        });

        dispatch(Actions.setLoading(false));
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveContentTypesError());
        onError(e);
      }
    },
};

const { reducer } = contentTypesSlice;

export { reducer, Actions, Async, Selectors };
